.ProjectShowcase {
    width: 100%;
    height: auto;

    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 50px;

    overflow: hidden;

    opacity: 0;
    transition: all 0.2s ease;
}


:root {
    --white-rgb: 255 255 255;
    --primary-rgb: var(--white-rgb);
}

.ProjectContainer {
    width: 75%;
    max-width: 600px;
    height: 200px;

    position: relative;

    display: flex;
    border: 3px solid rgb(var(--primary-rgb) / 80%);
    /* aspect-ratio: 10 / 16; */
    border-radius: 1rem;
    background-color: rgb(var(--primary-rgb) / 15%);
    overflow: hidden;
    position: relative;
    z-index: 10;
}

.ProjectContainer:after,
.ProjectContainer:before {
    content: "";
    height: 5px;
    position: absolute;
    z-index: 4;
    left: 50%;
    translate: -50% 0%;
    background-color: white;
}

.ProjectContainer:before {
    width: 15%;
    top: 0rem;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.ProjectContainer:after {
    width: 25%;
    bottom: 0rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}



.ProjectOverlay {
    background: linear-gradient(rgb(var(--primary-rgb) / 0.15),
            rgb(var(--primary-rgb) / 0.15) 3px,
            transparent 3px,
            transparent 9px);
    background-size: 100% 9px;
    height: 100%;
    width: 100%;
    animation: pan-overlay 22s infinite linear;
    position: absolute;
    z-index: 2;
    left: 0px;
    top: 0px;
}


.ProjectContainer>.ProjectBackground {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    left: 0px;
    top: 0px;
    background-size: 300%;
    background-position: 0% 0%;
    filter: sepia(100%) hue-rotate(160deg);
    opacity: 0.6;
    animation: pan-image 15s linear infinite;
}

.ProjectContainer>.ProjectContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    gap: 4rem;
    position: relative;
    z-index: 3;
    margin: 1rem;
    /* padding-bottom: 6rem; */
    border: 1px solid rgb(var(--primary-rgb) / 50%);
    border-radius: 0.6rem;
}

.ProjectContainer>.ProjectContent>.ProjectIcon {
    color: white;
    font-size: 3rem;
    position: absolute;
    top: 10px;
    left: 0;
    text-shadow: 0px 0px 0.5rem white;
}

.ProjectContainer>.ProjectContent>.ProjectUser {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    position: relative;
}

.ProjectContainer>.ProjectContent>.ProjectUser:before {
    height: 2px;
    width: 50px;
    translate: -20px -1rem;
    opacity: 0.75;
}

.ProjectContainer>.ProjectContent>.ProjectUser:after {
    height: 3px;
    width: 30px;
    translate: 26px calc(-1rem - 0.5px);
}

.ProjectContainer>.ProjectContent>.ProjectUser> :is(.ProjectName, .link) {
    font-family: "progress";
    color: white;
    text-align: center;
    text-transform: uppercase;
    text-shadow: 0px 0px 0.5rem white;
}

.ProjectContainer>.ProjectContent>.ProjectUser>.ProjectName {
    position: relative;
    font-size: 2.5rem;
    font-weight: 400;
}

.ProjectContainer>.ProjectContent>.ProjectUser>.UserLogo {
    width: 100px;
    filter: drop-shadow(0 0 0.5rem white);
}

.ProjectContainer>.ProjectContent>.ProjectUser>.link:is(:hover, :focus) {
    text-decoration: underline;
}



.ProjectShowcaseAni {
    width: 100%;
    height: auto;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 50px;

    overflow: hidden;
}


.ProjectShowcaseAni .ProjectContainer {
    transform: translateX(100%);
    opacity: 0;
    scale: 0.8;
}

.ProjectShowcaseAni .ProjectContainer:nth-child(1) {
    animation: SlideInRight 1s forwards ease-out;
}

.ProjectShowcaseAni .ProjectContainer:nth-child(2) {
    animation: SlideInRight 1s 200ms forwards ease-out;
}

.ProjectShowcaseAni .ProjectContainer:nth-child(3) {
    animation: SlideInRight 1s 400ms forwards ease-out;
}

@keyframes SlideInRight {
    0% {
        opacity: 0;
        scale: 0.8;
        transform: translateX(100%);
    }

    100% {
        opacity: 1;
        scale: 1;
        transform: translateX(0%);
    }
}

@keyframes pan-image {
    0% {
        background-position: 36% 42%;
        background-size: 150%;
    }

    20% {
        background-position: 30% 35%;
        background-size: 150%;
    }

    20.0001% {
        /* -- View 2 -- */
        background-position: 60% 85%;
        background-size: 250%;
    }

    40% {
        background-position: 49% 81%;
        background-size: 250%;
    }

    40.0001% {
        /* -- View 3 -- */
        background-position: 80% 42%;
        background-size: 200%;
    }

    60% {
        background-position: 84% 33%;
        background-size: 200%;
    }

    60.0001% {
        /* -- View 4 -- */
        background-position: 0% 0%;
        background-size: 200%;
    }

    80% {
        background-position: 15% 4%;
        background-size: 200%;
    }

    80.0001% {
        /* -- View 5 -- */
        background-position: 80% 10%;
        background-size: 200%;
    }

    100% {
        background-position: 72% 14%;
        background-size: 200%;
    }
}

@keyframes pan-overlay {
    from {
        background-position: 0% 0%;
    }

    to {
        background-position: 0% -100%;
    }
}

@media only screen and (max-width: 768px) {
    .ProjectShowcase {
        gap: 15px;
    }

    .ProjectShowcaseAni {
        gap: 15px;
    }
}