/* style.module.css */
.HeroSection {
    position: relative;
    width: 100vw;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
}

.HeroTitle {
    font-family: progress;
    font-size: 80px;
    line-height: 1.5;
    max-width: 70%;
    /* min-height: 300px; */
    overflow: hidden;
    white-space: pre-wrap;
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding-top: 50px; */

    position: absolute;
    top: 150px;
}

.HeroTitle div {
    display: inline-block;
}

/* .HeroTitle,
.HeroDesc {
    animation: titleExit linear forwards;
    animation-timeline: view();
    animation-range: exit -100px;
} */

.HeroActionCall {
    width: 100%;
    height: 45%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 25px;
    flex-direction: row;
    position: absolute;
    bottom: 75px;
}

.HeroActionCall button {
    width: 35%;
    height: 75px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(4.1px);
    -webkit-backdrop-filter: blur(4.1px);
    border: 3px solid rgb(255, 255, 255);

    -webkit-box-reflect: below 1px linear-gradient(transparent, #0005);
    box-shadow: 0 0 5px #03e9f4, 0 0 5px #03e9f4, 0 0 25px #03e9f4, 0 0 100px #03e9f4;

    color: white;
    font-size: 32px;
    font-family: progress;

    transition: all 0.3s ease;

    opacity: 0;
    animation: FadeIn 1s 1.5s forwards;
}

.HeroActionCall button:hover {
    cursor: pointer;
    -webkit-box-reflect: below 1px linear-gradient(transparent, #0005);
    box-shadow: 0 0 40px #03e9f4, 0 0 40px #03e9f4, 0 0 100px #03e9f4, 0 0 150px #03e9f4;
    transition: all 0.3s ease;
}

@keyframes FadeIn {
    0% {
        opacity: 0;
        transform: translateY(50px)
    }

    100% {
        opacity: 1;
        transform: translateY(0px)
    }
}

@keyframes typing {
    from {
        width: 0
    }

    to {
        width: 100%
    }
}

@keyframes titleEntry {
    from {
        scale: 0.8;
        opacity: 0;
    }

    to {
        scale: 1;
        opacity: 1;
    }
}

@keyframes titleExit {
    from {
        scale: 1;
        opacity: 1;
    }

    to {
        scale: 0.8;
        opacity: 0;
    }
}

/* ================================================================================================== */
/* ================================================================================================== */
/* ================================================================================================== */

.CatagorieSection {
    position: relative;
    width: 100vw;
    min-height: auto;
}

.TitleSection {
    width: 100%;
    height: auto;
    text-align: center;
    color: white;
    font-size: 32px;
    font-family: progress;
    position: relative;
    padding-top: 100px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.TitleSection::after {
    content: "";
    width: 90%;
    height: 4px;
    background-color: gray;
    display: block;
    border-radius: 4px;
}

.CatagoriesMainContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 50px;

    margin-top: 100px;

}

.CatagoriesMainContainerAni {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 50px;

    margin-top: 100px;
}

.CatagorieContainer {
    width: 300px;
    height: 300px;

    background: rgba(255, 255, 255, 0.05);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(4.1px);
    -webkit-backdrop-filter: blur(4.1px);
    border: 1px solid rgba(255, 255, 255, 0.3);

    opacity: 0;
    animation: FadeFlowOut 0.2s forwards;

    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;

    color: white;
    transition: all 0.2s ease;

}

.CatagoriesMainContainerAni a:nth-child(1) .CatagorieContainer {
    animation: FadeFlowIn 1s ease forwards;
}

.CatagoriesMainContainerAni a:nth-child(2) .CatagorieContainer {
    animation: FadeFlowIn 1s 0.2s ease forwards;
}

.CatagoriesMainContainerAni a:nth-child(3) .CatagorieContainer {
    animation: FadeFlowIn 1s 0.4s ease forwards;
}

.CatagoriesMainContainerAni a:nth-child(4) .CatagorieContainer {
    animation: FadeFlowIn 1s 0.6s ease forwards;
}


@keyframes FadeFlowIn {
    from {
        transform: translateY(50px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes FadeFlowOut {
    from {
        transform: translateY(0);
        opacity: 1;
    }

    to {
        transform: translateY(50px);
        opacity: 0;
    }
}

.CatagorieContainer:hover {
    cursor: pointer;
    box-shadow: 0 0 5px #03e9f4, 0 0 5px #03e9f4, 0 0 25px #03e9f4, 0 0 50px #03e9f4;
    transition: all 0.2s ease;
}

.CatagorieContainer .icon {
    font-size: 80px;
}

.CatagorieContainer .title {
    font-size: 28px;
    text-align: center;
    font-family: progress;
}

.CatagorieContainer .desc {
    font-size: 24px;
}

.ProjectsSection {
    width: 100vw;
    min-height: auto;
    margin-top: 200px;
    display: flex;
    /* align-items: center; */
    justify-content: center;
}

.ProjectsContainer{
    /* min-width: 1650px; */
    width: 90%;
    max-width: 2250px;
    height: auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 50px;

    background: rgba(255, 255, 255, 0.05);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(4.1px);
    -webkit-backdrop-filter: blur(4.1px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.ProjectContent{
    position: relative;
    width: 50%;
}

.ProjectsSection .TitleSection{
    position: relative;
    width: 50%;
    text-align: left;
    font-size:  3vw;
    padding-top: 0;
}

.ProjectsSection .TitleSection::after{
    background-color: white;
    width: 100%;
}

.PartnersSection {
    width: 100vw;
    height: 75vh;
    margin-top: 150px;
    position: relative;
}

.PartnersContainer {
    width: 100%;
    height: auto;

    display: flex;
    align-items: center;
    justify-content: center;
}

.PartnersContainer span {
    color: white;
    font-family: progress;
    font-size: 36px;
    margin-top: 25px
}

@media only screen and (max-width: 768px) {
    .HeroSection {
        position: relative;
        width: 100vw;
        height: 100vh;
    
        display: flex;
        align-items: center;
        justify-content: normal;
        flex-direction: column;
    }
    
    .HeroTitle {
        position: relative;
        text-align: center;
        width: auto;
        max-width: 90%;
        font-size: 12vw;
        font-family: ProgressBold;
        color: white;
    }

    .HeroActionCall {
        width:  90%;
        height: 45%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        gap: 25px;
        flex-direction: row;
        position: absolute;
        bottom: 75px;
    }

    .HeroActionCall button {
        width: 100%;
        height: 75px;
        background: rgba(255, 255, 255, 0.05);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(4.1px);
        -webkit-backdrop-filter: blur(4.1px);
        border: 3px solid rgb(255, 255, 255);

        -webkit-box-reflect: below 1px linear-gradient(transparent, #0005);
        box-shadow: 0 0 5px #03e9f4, 0 0 5px #03e9f4, 0 0 25px #03e9f4, 0 0 100px #03e9f4;

        color: white;
        font-size: 32px;
        font-family: progress;

        transition: all 0.3s ease;

        opacity: 0;
        animation: FadeIn 1s 3s forwards;
    }

    .ProjectsContainer{
        width: 90%;
        max-width: 2250px;
        height: auto;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 15px;
        gap: 50px;
    
        background: rgba(255, 255, 255, 0.05);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(4.1px);
        -webkit-backdrop-filter: blur(4.1px);
        border: 1px solid rgba(255, 255, 255, 0.3);
    }

    .ProjectsSection .TitleSection{
        position: relative;
        width: 100%;
        text-align: center;
        font-size:  5vw;
        padding-top: 0;
    }

    .ProjectContent{
        width: 100%;
    }

}