.PPContainer {
    width: 100vw;
    height: auto;
    padding-top: 100px;
    display: flex;
    /* justify-content:center; */
    align-items: center;
    flex-direction: column;
}

.TitleContainer {
    color: white;
    width: 100vw;
    height: 90px;
    text-align: center;
    font-family: progress;
    font-size: 72px;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.TitleContainer::after {
    content: "";
    width: 90%;
    height: 4px;
    background-color: gray;
    display: block;
    border-radius: 4px;
    position: absolute;
    bottom: 0;
}

.PPDesc{
    width: 90%;
    text-align: center;
}

.PPSection{
    width: 95%;
    margin-top: 50px;
    height: auto;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(4.1px);
    -webkit-backdrop-filter: blur(4.1px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
}

h2 {
    color: white;
    font-family: progress;
    font-size: 36px;
    text-decoration: underline;
    padding-bottom: 10px;
}

p {
    color: white;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 18px;
    line-height: 1.6;
}

ul{
    color: white;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 18px;
    line-height: 1.6;
    margin-left: 50px;
}