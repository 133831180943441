@font-face {
    font-family: Progress;
    src: url("Fonts/ProgressRegular-ALdZg.ttf");
}

@font-face {
    font-family: Progress3D;
    src: url("Fonts/Progress3DRegular-qZ5yq.ttf");
}

@font-face {
    font-family: ProgressBold;
    src: url("Fonts/ProgressBold-jEyYy.ttf");
}

@font-face {
    font-family: ProgressExtended;
    src: url("Fonts/ProgressExtendedRegular-51R9v.ttf");
}

*{
    --footer-height: 100px;
    --footer-topMargin: 125px;
}

