.ContactSection {
    width: 100vw;
    height: calc(100vh - (var(--footer-height) - var(--footer-topMargin)));

    display: grid;
    place-items: center;

}

.ContactSection:hover {
    cursor: default;
}

.ContactContainer {
    width: 75%;
    height: 75%;
    position: relative;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(0.05px);
    -webkit-backdrop-filter: blur(0.05px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 16px;
    overflow: hidden;
    display: grid;
    place-items: center;
}

.ContactContainer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('Media/Images/cityBG.png');
    width: 100%;
    height: 100%;
    background-size: cover;
}

.ContactBtnContainer {
    position: absolute;
    width: 90%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 100px;
}

.formBtn {
    color: black;
    font-size: 48px;
    position: relative;
    z-index: 999;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: none;
    background-color: transparent;
}

.formBtn::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    transform: scale(1);
    background-color: white;
    border-radius: 50%;
    top: 0;
    left: 0;
    border: none;
    transition: all 0.2s ease;
}

.formBtn:nth-child(1):before {
    animation: BtnPulse 1s ease-in-out infinite;
}

.formBtn:nth-child(2):before {
    animation: BtnPulse 1s 0.1s ease-in-out infinite;
}

.formBtn:nth-child(3):before {
    animation: BtnPulse 1s 0.2s ease-in-out infinite;
}

.formBtn svg {
    display: inline-block;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ContactBtnContainer .formBtn:hover {
    cursor: pointer;
}

.ContactBtnContainer .formBtn:hover::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    transform: scale(1.2);
    background-color: white;
    border-radius: 50%;
    top: 0;
    left: 0;
    border: none;
    animation: none;
    transition: all 0.5s ease;
}

.ContactBtnContainerClosed{
    position: absolute;
    width: 90%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 100px;
}

.ContactBtnContainerClosed .formBtn{
    opacity: 0;
    transition: all 1.2s ease;
}

.ContactBtnContainerClosed .formBtn:nth-child(1):before {
    animation: BtnExit 1s ease-out forwards;
}

.ContactBtnContainerClosed .formBtn:nth-child(2):before {
    animation: BtnExit 1s 0.1s ease-out forwards;
}

.ContactBtnContainerClosed .formBtn:nth-child(3):before {
    animation: BtnExit 1s 0.2s ease-out forwards;
}

@keyframes BtnPulse {
    0% {
        background-color: white;
        transform-origin: center;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }

    50% {
        background-color: rgba(255, 255, 255, .8);
        transform-origin: center;
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -ms-transform: scale(1.1);
        -o-transform: scale(1.1);
        transform: scale(1.1);
    }
}

@keyframes BtnExit {
    0% {
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        transform: translateY(-15px);
        opacity: 0;
    }
}

.ContactContent {
    width: 90%;
    height: 90%;
    background: rgba(255, 255, 255, 0.25);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(4.1px);
    -webkit-backdrop-filter: blur(4.1px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    position: absolute  ;
    z-index: 9999;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
    animation: contactForm-Entrance 1s forwards;
}

.ContactBackgroundOverlay {
    background: linear-gradient(rgb(var(--primary-rgb) / 0.15),
            rgb(var(--primary-rgb) / 0.15) 3px,
            transparent 3px,
            transparent 9px);
    background-size: 100% 9px;
    height: 100%;
    width: 100%;
    animation: pan-overlay 22s infinite linear;
    position: absolute;
    z-index: 2;
    left: 0px;
    top: 0px;
}

.closeFormBtn{
    position: absolute;
    top: 5px;
    right: 5px;
    color: white;
    font-size: 48px;
    z-index: 9999;
}
.closeFormBtn:hover{
    cursor: pointer;
}

.infoContainer{
    width: 90%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content:center ;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 5px;
}

.infoContent{
    width: 45%;
    height: 90%;
    min-width: 260px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    color: white;
    font-size: 64px;
}
.infoContent h1{
    font-size: 54px;
    font-family: progress;
    text-align: center;
}

.infoContent span{
    font-size: 28px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
}

@media only screen and (min-width: 768px) {
    .infoContainer{
        width: 90%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content:center ;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 5px;
    }
    .HDivider{
        height: 90%;
        width: 4px;
        border-radius: 4px;
        display: block;
        background-color: gray;
    }
}


.ContactContent h1{
    color: white;
    font-family: progress;
    font-size: 72px;
    margin-top: 15px;
    width: 100%;
    height: auto;
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.ContactContent h1::after{
    content: "";
    width: 75%;
    height: 4px;
    border-radius: 4px;
    background-color: gray;
    display: block;
    position: relative;
}

.ContactContent form{
    width: 100%;
    max-width: 350px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 25px;
    margin-top: 50px;
}

.ContactContent form input{
    width: 100%;
    height: 50px;
    border-radius: 50px;
    border: none;
    padding-left: 15px;
}

.ContactContent form textarea{
    width: 100%;
    height:100px;
    border-radius: 25px;
    border: none;
    padding-left: 15px;
}

.ContactContent form button{
    width: 75%;
    height: 50px;
    border-radius: 50px;
    background-color: white;
    border: none;
}

.ContactContent form button:hover{
    cursor: pointer;
}


@keyframes contactForm-Entrance {
    0% {
        width: 0%;
        height: 5%;
    }

    50% {
        width: 90%;
        height: 5%;
    }

    100% {
        width: 90%;
        height: 90%;
    }

}

@keyframes pan-overlay {
    from {
        background-position: 0% 0%;
    }

    to {
        background-position: 0% -100%;
    }
}


@media (prefers-reduced-motion) {
    .ContactBackgroundOverlay {
        display: none;
    }
}