.Footer{
    width: 100vw;
    height: var(--footer-height);
    margin-top: var(--footer-topMargin);
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(4.1px);
    -webkit-backdrop-filter: blur(4.1px);
    border-top: 1px solid rgba(255, 255, 255, 0.3);    
    position: relative;
}

.Links{
    position: relative;
    height: 70%;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    font-size: 45px;
}

.Links a{
    filter: drop-shadow(0 0 0 white);
    transition: 0.5s ease;
}

.Links a:hover{
    filter: drop-shadow(0 0 0.5rem white);
    transition: 0.5s ease;
}

.Bottom{
    width: 100%;
    position: relative;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 30%;
    background: rgba(255, 255, 255, 0.25);
    text-align: center;
    color: white;
    font-family: progress;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
}