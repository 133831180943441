.NavContainer {
    width: 100vw;
    height: 75px;
    position: fixed;

    z-index: 9999;

    background: rgba(255, 255, 255, 0.15);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(4.1px);
    -webkit-backdrop-filter: blur(4.1px);

    transition: all 0.5s ease;
}

.NavContainerTop {
    width: 100vw;
    height: 75px;
    position: fixed;


    z-index: 9999;

    transition: all 0.5s ease;
}

.NavContent {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
}

.Brand {
    /* margin-left: 25px; */
    position: absolute;
    left: 25px;
}

.Brand img {
    width: 75px;
}

.NavLinks {
    margin-right: 100px;
    position: absolute;
    right: 0;
    display: flex;
    gap: 50px;
    font-size: 24px;
    font-family: progress;
}

.NavLinks a {
    color: white;
    position: relative;
}

a.active {
    color: green;
}

.NavLinks a:not(.activeLink)::after {
    content: "";
    width: 0%;
    height: 3px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: white;
    transition: all 0.2s ease;
}

.NavLinks a:hover::after {
    width: 100%;
    transition: all 0.2s ease;
}



/* =========================================================================================== */
/* =========================================================================================== */
/* =========================================================================================== */



@media only screen and (max-width: 768px) {
    .NavContainer {
        width: 100vw;
        height: 125px;
        position: fixed;

        z-index: 9999;

        background: rgba(255, 255, 255, 0.15);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(4.1px);
        -webkit-backdrop-filter: blur(4.1px);

        transition: all 0.5s ease;
    }

    .NavContainerTop {
        width: 100vw;
        height: 75px;
        position: fixed;


        z-index: 9999;

        transition: all 0.5s ease;
    }

    .Brand {
        /* margin-left: 25px; */
        position: absolute;
        left: 25px;
        top: 10px;
    }

    .Brand img {
        width: 100px;
    }

    .NavContainer:has(.MobileNavToggleActive),
    .NavContainerTop:has(.MobileNavToggleActive) {
        height: 100vh;
        background: rgba(255, 255, 255, 0.15);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(4.1px);
        -webkit-backdrop-filter: blur(4.1px);
        z-index: 9999;

    }


    .MobileNavToggle {
        width: 75px;
        height: 75px;
        /* background-color: red; */
        position: absolute;
        right: 25px;
        top: 25px;
        z-index: 9999;

        transform: rotate(-45deg);
        transition: all 0.2s ease;
    }

    .openBtn,
    .closeBtn {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
        transition: 0 all;
        z-index: 9999;

    }

    .MobileNavToggle .closeBtn {
        visibility: hidden;
        transition: 0 all;
        transition-delay: 0.5s
        
    }

    .MobileNavToggleActive .openBtn {
        visibility: hidden;
        transition: 0 all;
        transition-delay: 0.5s
    }

    .MobileNavToggle .openBtn .midLine {
        width: 75px;
        height: 8px;
        background-color: white;
        position: relative;
        animation: openBtnAniClose 0.5s forwards;
        z-index: 9999;

    }

    .MobileNavToggle .openBtn .topLine,
    .MobileNavToggle .openBtn .bottomLine {
        content: "";
        width: 50px;
        height: 8px;
        background-color: white;
        display: block;
        position: relative;
        transform: translateX(0px);
        animation: openBtnAniClose 0.7s forwards;
        z-index: 9999;

    }


    .MobileNavToggle .closeBtn .LeftLine {
        width: 100%;
        height: 8px;
        background-color: white;
        position: relative;
        position: absolute;
        animation: closeBtnAniRight 0.5s reverse;
        z-index: 9999;


    }

    .MobileNavToggle .closeBtn .RightLine {
        width: 100%;
        height: 8px;
        background-color: white;
        position: relative;
        position: absolute;
        animation: closeBtnAniLeft 0.5s reverse;
        z-index: 9999;

    }

    .MobileNavToggleActive .closeBtn .LeftLine,
    .MobileNavToggleActive .closeBtn .RightLine {
        width: 100%;
        height: 8px;
        background-color: white;
        position: relative;
        position: absolute;
        transform: translateX(150px);
        animation: closeBtnAniRight 0.5s 0.3s forwards;
        z-index: 9999;

    }

    .MobileNavToggleActive .closeBtn .LeftLine {
        transform: rotate(90deg) translateX(-150px);
        animation: closeBtnAniLeft 0.5s 0.3s forwards;
        z-index: 9999;

    }


    .MobileNavToggleActive {
        width: 75px;
        height: 75px;
        position: absolute;
        right: 25px;
        top: 25px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
        transform: rotate(-45deg);
        z-index: 9999;

    }

    .MobileNavToggleActive .midLine {
        width: 75px;
        height: 8px;
        background-color: white;
        position: relative;
        animation: openBtnAniOpen 0.5s forwards;
        transform: translateX(0px);
        z-index: 9999;

    }

    .MobileNavToggleActive .topLine,
    .MobileNavToggleActive .bottomLine {
        content: "";
        width: 75px;
        height: 8px;
        background-color: white;
        display: block;
        position: relative;
        animation: openBtnAniOpen 0.4s 0.1s forwards;
        transform: translateX(0px);
        z-index: 9999;

    }

    .NavLinks {
        display: none;
    }

    .NavContainer:has(.MobileNavToggleActive) .NavLinks,
    .NavContainerTop:has(.MobileNavToggleActive) .NavLinks {
        width: 100vw;
        height: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: absolute;
        left: 0;
        font-size: 36px;
        z-index: 9999;

    }

    .NavLinks a:not(.activeLink)::after {
        content: "";
        width: 0%;
        height: 3px;
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: white;
        transition: all 0.2s ease;
        z-index: 9999;

    }
    
    .NavLinks a:hover::after {
        width: 100%;
        transition: all 0.2s ease;
        z-index: 9999;

    }

    @keyframes openBtnAniOpen {
        0% {
            transform: translateX(0px);
        }

        100% {
            transform: translateX(150px);
        }
    }

    @keyframes openBtnAniClose {
        0% {
            transform: translateX(150px);
        }

        100% {
            transform: translateX(0px);
        }
    }


    @keyframes closeBtnAniRight {
        0% {
            transform: rotate(90deg) translateX(-150px);

        }

        100% {
            transform: rotate(90deg) translateX(0px);

        }
    }

    @keyframes closeBtnAniLeft {
        0% {
            transform: translateX(150px);

        }

        100% {
            transform: translateX(0px);
        }
    }
}