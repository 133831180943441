* {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    color: white;
}

body {
    overflow-x: hidden;
    cursor: none;
}

.appBackground {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url('Media/Images/background.svg');
    background-size: cover;
    z-index: -1;
    background-color: #15172f;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: #15172f;
}

::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 8px;
}

.cursor {
    width: 20px;
    height: 20px;
    border: 2px white solid;
    border-radius: 50%;
    position: fixed;
    opacity: 1;
    top: -20px;
    left: -20px;
    z-index: 0;
}

.cursor.clicked {
    animation: ClickedAni 500ms forwards;
}

.cursor.pointer {
    animation: pointerAni 1s infinite alternate ease-in-out;
}

@keyframes ClickedAni {
    0% {
        scale: 1;
        opacity: 1;
        border: 2px white solid;
    }

    100% {
        scale: 4;
        border-image: linear-gradient(#ffffff, #7f7f7f) 20;
        border-width: 4px;
        border-style: solid;
        opacity: 0;
    }
}

@keyframes pointerAni {
    0% {
        scale: 1;
        border: 2px white solid;
    }

    100% {
        scale: 2;
        border: 4px rgba(255, 255, 255, 0.4) solid;
    }
}